<template>
  <v-card v-if="typeOfUser === 'Free'">
    <v-card-text>
        <br>
        <br>
        <br>
        <div class="text-h5 text-center font-weight-bold" align-center>
                This feature is available on paid version
        </div>
        <br>
        <br>
    </v-card-text>
  </v-card>
  <div class="" v-else>
    <QueriesList :project="project" type="functional"></QueriesList>
  </div>
</template>

<script>
import QueriesList from '../../../views/common/QueriesList.vue';
export default {
  name: 'financialclose_virtualconcierge_list',
  // props: ['props'],
  data: function () {
    return {
      project: "",
      typeOfUser: 'Paid',
    }
  },
  components: {
    QueriesList
  },
  created() {
    this.init();
  },
  activated() {
    this.init();
  },
  methods: {
    init() {
      this.$store.commit("setProjectName", "");
      this.project = this.$route.params.id;
      console.log(this.$store.state.subscriptions.financialclose.plan_detail.name);
      if (this.$store.state.subscriptions.financialclose.plan_detail.name) {
        this.typeOfUser = this.$store.state.subscriptions.financialclose.plan_detail.name || "Paid";
      }
    },

  },
  watch: {
  }
}
</script>
